/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'
import { QuoteItem } from './wallOfLoveIterfaces'
import { Img } from 'components/basic/Img'
import styles from './WallOfLove.module.scss'
import { useMouseFaceTracking } from 'components/_hooks/useMouseFaceTracking'
import { Type } from 'components/Type'

export const QuoteCard: FC<QuoteItem> = (props) => {
	const [containerRef, handleMouseMove, handleMouseLeave] = useMouseFaceTracking<HTMLDivElement>()

	return (
		<div
			className={`${styles.quote_card} ${props.className} ${styles.container}`}
			ref={containerRef}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
		>
			{props.imageSrc && (
				<Img
					src={props.imageSrc}
					alt={props.name}
				/>
			)}
			<Type.Body2>{props.quote}</Type.Body2>
			<Type.SmallPrint className={styles.quote_card_name}>
				{props.name}, {props.description}
			</Type.SmallPrint>
		</div>
	)
}
